import axios from 'axios';
import { useSpotStore } from './stores/spots.js';

let baseUrl = "http://localhost:8000";
if(window.location.origin === "https://parkingapp.fallforrising.com") {
  baseUrl = "https://parkingheroku-87031688bac7.herokuapp.com";
}

const api = axios.create({
  baseURL: baseUrl + '/api/', // Django server URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;

export const getParkingSpots = async () => {    
    try {
        const spots = useSpotStore();
        const response = await api.get('spots/');
        spots.initialization(response.data);
        return response.data;
    } catch (error) {
      console.error('Error fetching parking spots:', error);
    }
  };